.mdw-about{
    padding-top: 100px;
    @include media-breakpoint-up(xl){
        padding-top: 120px;
    }
    .subHeading{
        color:$brand-secondary;
        font-weight: 500;
        strong{
            color:#559cea;
        }
    }
}
.mdw-aboutImg{
    margin-top: -40px;
}
.mdw-cta{
    padding-top: 50px;
    p{
        color:$brand-third;
        font-size: 23px;
        font-weight: bold;
        margin-bottom: 30px;
    }
}
.mdw-about_item{
    text-align: center;
    margin-bottom: 50px;
    @include media-breakpoint-up(md){
        margin-bottom: 0;
    }
    p{
        color:$brand-text;
        font-size: 20px;
        font-weight: 500;
    }
    &:nth-child(2) p{
        padding-left: 80px;
    }
    .mdw-about_year{
        color:$brand-primary;
        display: block;
        font-family: $fontTitles;
        font-size: 62px;
        font-weight: bold;
        text-shadow: 3px 3px 0 #ffff, 5.1px 4.8px 9px rgba(13, 13, 13, 0.2);
                     
        @include media-breakpoint-up(md){
            font-size: 62px;
        }
    }
}
.mdw-sevices{
    @include media-breakpoint-up(md){
        padding-bottom: 100px;
    }
}
.mdw-services-img{
    @include media-breakpoint-down(lg){
        margin-bottom: 50px;
    }
}
.mdw-services-txt{
    @include media-breakpoint-down(md){
        padding-left: 8.3333333%;
        padding-right: 8.33333333%;
    }
    @include media-breakpoint-up(lg){
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: space-between;
        padding-top: 30px;
        padding-bottom: 30px;
    }
    .mdw-services-wrap{
        margin-bottom: 50px;
        @include media-breakpoint-up(lg){
            margin-bottom: 0;
        }
    }
    span{
        color:$brand-primary;
        font-size: 20px;
        font-weight: 500;
        display: block;
        margin-bottom: 10px;
    }
    p{
        color:$brand-text;
        font-size: 18px;
        font-weight: 500;
    }
}

.mdw-servicesList{
    .mdw-servicesItem{
        margin-bottom: 50px;
        @include media-breakpoint-up(lg){
            &:nth-child(even){
                margin-top: 30px;
            }
        }
        &_wrap{
            background: $brand-light;
            box-shadow: 0 20px 20px 0 rgba(0,0,0,0.2);
            color:#3243c7;
            padding: 20px;
            text-align: center;
            @include media-breakpoint-only(md){
                height: 100%;
            }
            span{
                color:#1a257e;
                font-weight: 700;
            }
        }
        &_img{
            margin-bottom: 20px;
        }
    }
}
.mdw-retail_logos{
    margin-bottom: 50px;
}
.mdw-retail_ecom{
    margin-bottom: 50px;

    span{
        color:$brand-primary;
        font-size: 16px;
        font-weight: 500;
        display: block;
        margin-bottom: 30px;
        @include media-breakpoint-up(md){
            font-size: 18px;
        }
    }
    @include media-breakpoint-up(md){
        align-items: center;
        justify-content: center;
    }
}
.mdw-retail_stores{
    margin-bottom: 30px;
    @include media-breakpoint-up(md){
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 50px;
        margin-bottom: 0;
    }
}
.mdw-retail_1{
    @include media-breakpoint-up(md){
        border-right: 2px solid $brand-primary;
    }
}
.mdw-reatil_customer{
    text-align: center;
    margin-bottom: 40px;
    &:last-child{
        margin-bottom: 0;
    }
    @include media-breakpoint-up(md){
        margin-bottom: 0;
    }
    span{
        color:#1a257e;
        font-size: 20px;
        font-weight: 500;
        display: block;
        margin-bottom: 20px;
    }
    picture{
        margin-bottom: 20px;
        @include media-breakpoint-down(sm){
            display: block;
            margin-bottom: 20px;
            text-align: center;
        }
    }
}

.mdw-cats{
    h2{
        margin-bottom: 50px;
    }
}