footer{
    background: $brand-primary;
    color:$brand-light;
    .logoFtr{
        margin-bottom: 50px;
        svg{
            .st0,
            .st1{
                fill:$brand-light;
            }
        }
    }
    address{
        font-style: normal;
        font-size: 30px;
        font-weight: 700;
        font-family: $fontTitles;
        @include media-breakpoint-up(md){
            font-size: 32px;
        }
    }
    .ftrCtc{
        display: flex;
        font-family: $fontTitles;
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 25px;
        justify-content: center;
        @include media-breakpoint-up(md){
            margin-bottom: 50px;
            justify-content: initial;
        }
        a{
            color:$brand-light;
        }
        span{
            text-align: left;
            &:first-child{
                padding-right: 5px;
            }
        }
    }
    .mdw-linksFtr{
        display: flex;
        justify-content: center;
        a{
            color:$brand-light;
            font-size: 14px;
            margin: 0 10px;
        }
    }
}