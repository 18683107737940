.mdw-blog{
    background: url('/img/blog/blog-midway.jpg') no-repeat center 80px;
    padding-top: 100px;
    padding-bottom: 50px;
    @include media-breakpoint-up(md){
        background-size: contain;
        padding-top: 90px;
    }
    @include media-breakpoint-up(xl){
        padding-top: 160px;
    }
    &_title{
        padding-bottom: 35px;
        @include media-breakpoint-up(md){
            padding-bottom: 70px;
        }
    }
    &_filters{
        select{
            appearance: none;
            background: url('/img/blog/arrow-down.svg') white no-repeat 97% center;
            background-size: 15px;
            border: 1px solid $brand-primary;
            border-radius: 8px;
            color:$brand-primary;
            font-weight: 700;
            height: 50px;
            width: 100%;
            padding: 0 10px;
        }
        margin-bottom: 30px;
        @include media-breakpoint-up(md){
            margin-bottom: 0;
        }
    }
    &_searchBox{
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 100px;
        input{
            border: 1px solid $brand-primary;
            border-radius: 8px;
            color:$brand-primary;
            padding: 0 10px;
            height: 50px;
            flex-grow: 1;
            &:focus{
                outline: none;
            }
        }
        button{
            background: $brand-primary;
            border:none;
            border-radius: 8px;
            color:white;
            font-weight: 700;
            height: 50px;
            padding: 0 23px;
            margin-left: -10px;
        }
    }
    &_posts{
        margin-bottom: 50px;
    }
    &_post{
        margin-bottom: 50px;
        img{
            width: 100%;
        }
    }
    &_postBody{
        background: $brand-light;
        box-shadow: 5.1px 4.8px 9px 1px rgba(13, 13, 13, 0.1);
        padding: 40px 20px;
        text-align: center;
        h2{
            color:$brand-primary;
            font-size: 18px;
            font-weight: 600;
            @include media-breakpoint-up(md){
                font-size: 20px;
            }
        }
        p{
            font-size: 16px;
            font-weight: 500;
            padding-bottom: 20px;
            position: relative;
            @include media-breakpoint-up(md){
                font-size: 18px;
            }
        }
    }
}

.mdw-blog_det{
    &Title{
        position: relative;
        margin-bottom: 50px;
        @include media-breakpoint-up(md){
            margin-bottom: 100px;
        }
        h1 + span{
            color:$brand-third;
            font-size: 20px;
            @include media-breakpoint-up(md){
                font-size: 23px;
            }
        }
    }
    &Back{
        @include media-breakpoint-down(sm){
            display: none;
        }
        @include media-breakpoint-up(md){
            background: $brand-light;
            margin-bottom: 30px;
            span{
                width: 9px;
                line-height: 1;
                margin-right: 10px;
            }
        }
        @include media-breakpoint-up(xl){
            position: absolute;
            left: 0;
            margin-bottom: 0;
        }
    }
    &Img{
       position: relative;
       &::after{
        background: $brand-primary;
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: -55px;
        height: 160px;
        z-index: 1;
        @include media-breakpoint-up(md){
            bottom: -100px;
            height: 360px;
        }
       }
       picture{
           position: relative;
           z-index: 2;
       }
    }
    &Share{
        padding-top: 50px;
        @include media-breakpoint-up(md){
            padding-top: 0;
        }
        span{
            color:$brand-primary;
            font-weight: 600;
            display: block;
            margin-bottom: 20px;
        }
        .mdwShare{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            @include media-breakpoint-up(md){
                flex-direction: column;
                align-items: flex-start;
            }
            
            a{
                border-radius: 50%;
                text-decoration: none;
                transition: shadow 250ms ease;
                width: 54px;
                margin-right: 20px;
                @include media-breakpoint-up(md){
                    margin-right: 0;
                    margin-bottom: 25px;
                }
                &:not(.whats){
                    background: #bdd5ff;
                    padding: 15px;
                }
                &:hover{
                    box-shadow: 0 0 10px 0 rgba(black,0.4);
                }
            }
            
            
        }
    }
    &Body{
        padding-top: 110px;
        @include media-breakpoint-up(md){
            padding-top: 150px; 
        }    
        p{
            font-size: 18px;
            @include media-breakpoint-up(xl){
                font-size: 24px;
            }
        }
    }
    &Nav{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 100px 15px;
        a{
            border:1px solid $brand-primary;
            border-radius: 8px;
            color:$brand-primary;
            font-size: 16px;
            font-weight: 700;
            text-decoration: none;
            height: 45px;
            width: 160px;
            display: inline-flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 15px;
            line-height: 0;
            transition: all 250ms ease;
            span{
                width: 9px;
            }

            &:hover{
                border-color: $brand-third;
                color:$brand-third;
            }
        }
    }
    &Related{
        .heading2{
            margin-bottom: 50px;
        }
    }
}