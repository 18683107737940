.mdw-contactHeader{
    picture,img{
        width: 100%;
    }
}
.formBox{

    label{
        color:$brand-primary;
        font-size: 18px;
        font-weight: bold;
        display: block;
        margin-bottom: 5px;
    }
    input,textarea,select{
        background: none;
        border:none;
        border-bottom: 1px solid $brand-primary;
        color:$brand-text;
        padding: 0 15px;
        height: 50px;
        width: 100%;
        &:focus{
            outline: none;
        }
    }
    textarea{
        min-width: 100%;
        max-width: 100%;
        height: 100px;
        min-height: 100px;
        max-height: 100px;
        overflow: auto;
    }
    .input-g{
        position: relative;
        margin-bottom: 40px;
    }
    .danger{
        border-bottom-color: rgb(216, 87, 87);
    }

    .g-recaptcha{
        >div{
            margin: 0 auto 0 auto;
        }
    }
    .mdw-actions{
        padding-top: 50px;
    }
    .errorCaptcha{
        color:rgb(216, 87, 87);
        font-weight: 700;
        display: block;
        padding-top: 10px;
    }
}

.errors{
    background: rgb(216, 87, 87);
    color:$brand-light;
    padding: 20px;
    margin-bottom: 30px;
}

.message-sent {
    position: absolute;
    display: block;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    font-size: 23px;
    width: 90%;
    top: 33%;
    text-align: center;
    color: white;
    font-weight: 900;
    text-shadow: 1px 3px black;

    @include media-breakpoint-up(md) {
        font-size: 43px;
        width: 100%;
        top: 35%;
    }
}

.wrap-text-success {
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    width: 100%;
    top: 40%;
}
