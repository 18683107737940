header{
    background: rgba($brand-light,0.5);
    //backdrop-filter: blur(3px);
    padding-top: 10px;
    padding-bottom: 10px;
    position: fixed;
    top:0;
    left: 0;
    right: 0;
    z-index: 10;
    .mdw-logoContainer{
        z-index: 2;
        svg{
            transition: fill 250ms ease;
            .st0{fill:$brand-primary;}
            .st1{fill:#B5B9B9;}
        }
    }
    .lang{
        span{
            width: 15px;
            margin-right: 5px;
            line-height: 1;
        }
        a{
            color:$brand-primary;
            font-size: 18px;
            font-weight: 700;
            display: flex;
            align-items: center;
            text-decoration: none;
            transition: color 250ms ease;
            &:hover{
                color:#559cea;
            }
            @include media-breakpoint-up(lg){
                font-size: 16px;
            }
        }
    }
    .menuMb{
        z-index: 2;
        .hamburger{
            background: $brand-secondary;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 15px 10px;
            position: relative;
            height: 50px;
            width: 50px;
            @include media-breakpoint-up(lg){
                height: 70px;
                width: 70px;
                padding: 20px 15px;
            }
            span{
                background: $brand-light;
                display: block;
                height: 3px;
                width: 100%;
                &:nth-child(1),
                &:nth-child(3){
                    transition: transform 250ms ease;
                }
            }
        }
    }
    .mainMenu{
        @include media-breakpoint-down(lg){
            background: $brand-secondary;
            padding: 22vw 20px;
            position: fixed;
            z-index: 1;
            top: -100%;
            right: 0;
            bottom: 0;
            left: 0;
            height: 100%;
            width: 100%;
            transform: translateY(0);
            transition: transform 250ms ease;

        }

        ul{
            display: flex;
            justify-content: flex-end;
            list-style-type: none;
            margin-bottom: 0;
            @include media-breakpoint-down(lg){
                flex-direction: column;
                justify-content: space-between;
                height: 100%;
                padding: 0;
            }    
            li{
                padding: 0 15px;
                position: relative;
                @include media-breakpoint-down(lg){
                    text-align: right;
                    &::after{
                        background: transparent;
                        content: "";
                        position: absolute;
                        top: 0;
                        right: 0;
                        left: 0;
                        height: 100%;
                        width: 150%;
                        transition: background 250ms ease;
                        z-index: -1;
                    }
                }
                &.active,
                &.current{
                    @include media-breakpoint-down(lg){
                        &::after{
                            background: linear-gradient(to right, $brand-secondary, #1632b6);
                        }
                    }
                }
                &.current a{
                    pointer-events: none;
                    @include media-breakpoint-up(lg){
                        color: #559cea;
                    }
                }
            }
            a{
                color:$brand-primary;
                font-size: 16px;
                font-weight: 500;
                font-family: $fontTitles;
                text-decoration: none;
                @include media-breakpoint-down(lg){
                    color:$brand-light;
                    font-size: 30px;
                    font-weight: 700;
                    display: inline-block;
                    padding: 0 10px;
                    opacity: 0;
                    visibility: hidden;
                    transition: opacity 250ms, visibility 250ms ease;
                }
                @include media-breakpoint-only(md){
                    font-size: 50px;
                }
                @include media-breakpoint-up(lg){
                    &:hover{
                        color:#559cea;
                    }
                }
            }
        }
    }
}