.fadeSlideY{
    opacity: 0;
    transform: translateY(50px);
    transition: transform 250ms cubic-bezier(0,0,0.55,1), opacity 250ms cubic-bezier(0,0,0.55,1);

    &-in{
        opacity: 1;
        transform: translateY(0);
    }
}

.fadeEl{
    opacity: 0;
    transition: opacity 500ms cubic-bezier(0.4, 0, 1, 1);
    &-in{
        opacity: 1;
    }
}

.fadeSeq{
    &:not(.slick-slider){
    .fadeSeqChild{
        opacity: 0;
        transition: opacity 500ms cubic-bezier(0.4, 0, 1, 1);
    }
    }
    &-in{
        &:not(.slick-slider){
            @for $i from 1 through 4 {
                .fadeSeqChild:nth-child(#{$i}){
                    opacity: 1;
                    transition-delay: $i * (250ms);
                }    
            }
        }    
    }
}