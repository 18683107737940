body{
    font-family: $fontBase;

    &.noScroll{
        main{
            height: 100vh;
            overflow: hidden;
        }
        .mainMenu{
            transform: translateY(100%);
            a{
                opacity: 1;
                visibility: visible;
                transition-delay: 250ms;
            }
        }
        .mdw-logoContainer{
            svg{
                .st0{fill:$brand-light;}
                .st1{fill:$brand-light;}
            }
        }
        header .menuMb .hamburger{
            background: $brand-light;
            span{
                background: $brand-secondary;
                &:nth-child(1){
                    transform: translate(2px, 8px) rotate(45deg);
                    @include media-breakpoint-up(lg){
                        transform: translate(2px, 13px) rotate(45deg);
                    }
                }
                &:nth-child(2){
                    opacity: 0;
                }
                &:nth-child(3){
                    transform: rotate(-45deg) translate(7px, -5px);
                    @include media-breakpoint-up(lg){
                        transform: rotate(-45deg) translate(11px, -8px);
                    }
                }
            }
        }
    }
}

picture{
    display: inline-block;
    line-height: 0;
}

.mx-center-md{
    @include media-breakpoint-down(md){
        margin-left: auto;
        margin-right: auto;
    }
}

.text-center{
    text-align: center;
}

.heading{
    color:$brand-primary;
    font-size: 32px;
    font-family: $fontTitles;
    font-weight: bold;
    @include media-breakpoint-up(md){
        font-size: 52px;
    }
}

.heading2{
    color:$brand-primary;
    font-size: 25px;
    font-family: $fontTitles;
    font-weight: bold;
    @include media-breakpoint-up(md){
        font-size: 34px;
    }
}

.subHeading{
    color:$brand-third;
    font-size: 18px;
    display: block;
    margin-bottom: 50px;
    @include media-breakpoint-up(md){
        font-size: 22px;
    }
}

.primary-color{
    color:$brand-primary;
}

.mdwBtn{
    background: none;
    border: 1px solid $brand-primary;
    border-radius: 8px;
    color:$brand-primary;
    font-family: $fontTitles;
    font-size: 16px;
    font-weight: 700;
    min-width: 220px;
    padding: 15px 20px;
    position: relative;
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    transition: color 250ms ease, border-color 250ms ease;
    @include media-breakpoint-up(md){
        font-size: 16px;
    }
    span{
        color:$brand-primary;
        font-family: $fontTitles;
        position: relative;
        z-index: 2;
    }

    &:hover{
        border-color:$brand-third;
        color: $brand-third;
        span{
            color:$brand-third;
        }
    }
    &:focus{
        outline: none;
    }
}

.ptSection{
    @include media-breakpoint-up(md){
        padding-top: 160px;
    }
}

.padSection{
    padding-top: 50px;
    padding-bottom: 50px;
}

.pdTopm5{
    padding-top: 50px;
}

.mb5{
    margin-bottom: 50px;
}

.center-y-md{
    @include media-breakpoint-up(md){
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: center;
    }
}

.noPad-xs{
    @include media-breakpoint-down(md){
        padding-left: 0;
        padding-right: 0;
    }
}
.noGut-xs{
    @include media-breakpoint-down(md){
        --bs-gutter-x:0;
        --bs-gutter-y:0;
    }
}

.slick-dots {
    @include media-breakpoint-up(sm) {
        bottom: 15px;
        text-align: left;
        padding-left: 10%;
    }

    @include media-breakpoint-up(xl)  {
        bottom: 35px;
        padding-left: 15%;
    }

    @media (min-width: 1918px) {
        padding-left: 18%;
    }

    @media (min-width: 2100px) {
        padding-left: 25%;
    }

    li {
        &.slick-active {
            button {
                &:before {
                    color: #1a257e !important;
                }
            }
        }
    }
    button {
        &:before {
            font-size: 12px !important;

            @include media-breakpoint-up(md)  {
                font-size: 15px !important;
            }

            @media (min-width: 1918px) {
                font-size: 17px !important;
            }
        }
    }
}

.imgStrech{
    picture, picture img{
        width: 100%;
    }
}

.corp-ptSection {
    padding-top: 80px;

    @include media-breakpoint-up(md)  {
        padding-top: 130px;
    }

    ul {
        li {
            list-style: none;
        }
    }
}
