.mdw-Banners{
    padding-top: 90px;
    @include media-breakpoint-only(md){
        padding-top: 80px;
    }
    .mdw-BannerSldr{
        margin-bottom: 50px;
        @include media-breakpoint-up(md){
            margin-bottom: 100px;
        }
        .item{
            text-align: right;
            position: relative;
            &_data{
                text-align: left;
                margin-bottom: 35px;
                @include media-breakpoint-up(md){
                    margin-bottom: 0;
                    position: absolute;
                    top:50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                }
                h1{
                    color:$brand-primary;
                    font-size: 42px;
                    font-weight: bold;
                    @include media-breakpoint-up(xl){
                        font-size: 72px;
                    }
                }
                p{
                    color:$brand-primary;
                    font-size: 18px;
                    font-weight: 500;
                    margin-bottom: 30px;
                    @include media-breakpoint-up(lg){
                        font-size: 22px;
                    }
                }
            }
            &_ctas{
                display: flex;
                .mdwBtn{
                    @include media-breakpoint-down(sm){
                        font-size: 13px;
                        min-width: 150px;
                        padding: 15px;
                    }
                    &:first-child{
                        margin-right: 20px;
                    }
                }
            }
        }
    }
}
.mdw-trajectory{

    @include media-breakpoint-up(md){
        background: url('/img/sales-offices-midway-bg.jpg') no-repeat center center;
        background-size: contain;
        height: 296px;
        padding-top: 96px;
    }
    @include media-breakpoint-up(lg){
        height: 395px;
        padding-top: 100px;
    }
    @include media-breakpoint-up(xl){
        height: 542px;
        padding-top: 180px;
    }
    &Img{
        @include media-breakpoint-down(md){
            box-shadow: 0 20px 10px 0 rgba(0,0,0,0.3);
            position: relative;
        }
    }
    &Txt{
        background: $brand-primary;
        @include media-breakpoint-down(md){
            &.first{
                padding-top: 35px;
            }
            &.last{
                padding-bottom: 35px;
            }
        }
        @include media-breakpoint-up(md){
            background: none;
        }
        p{
            color:$brand-light;
            font-family: $fontTitles;
            font-size: 18px;
            font-weight: normal;
            text-align: center;
            @include media-breakpoint-up(lg){
                font-size: 22px;
                margin-bottom: 30px;
            }
            @include media-breakpoint-up(xl){
                font-size: 26px;
                margin-bottom: 50px;
            }
            span{
                font-size: 25px;
                font-weight: 700;
                display: block;
                @include media-breakpoint-up(md){
                    font-size: 18px;
                }
                @include media-breakpoint-up(lg){
                    font-size: 40px;
                    line-height: 1;
                }
            }
        }
        em{
            display: flex;
            align-items: center;
            justify-content: center;
            &::before{
                content: url('/img/bullet.svg');
                width: 8px;
                margin:-10px 5px 0 0;
            }
        }
    }
}


.mdw-border-wrapp{
    background: $brand-light;
    box-shadow: 0 20px 20px 0px rgb(0 0 0 / 20%);
    border-width: 1px;
    border-style: solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(to top, #858585, white);
    padding-top: 0;
    padding-bottom: 40px;
    position: relative;
    margin-bottom: 50px;
    @include media-breakpoint-up(md){
        padding: 0 12px 40px 12px;
        margin-bottom: 0;
        height: 100%;
    }
    @include media-breakpoint-up(lg){
        padding: 0 20px 40px 20px;
    }
    @include media-breakpoint-up(xl){
        padding: 0 60px 40px 60px;
    }

    figure{
       width: 100px;
       display: inline-block;
       margin:-40px 0 35px 0;
    }
    h3{
        color:$brand-primary;
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 20px;
    }
    p{
        color:$brand-secondary;
        font-size: 18px;
        font-weight: 500;
        padding: 0 10%;
        margin-bottom: 0;
    }
}

.mdw-approach{
    padding-top: 50px;
    padding-bottom: 50px;
    &Data{
        >p{
            color:#6e6e6e;
            font-size: 20px;
            font-weight: 500;
            margin-bottom: 140px;
        }
    }
    &_item{
        padding-bottom: 50px;
    }
}

.mdw-national_img{
    position: relative;
    .container{
        color:$brand-light;
        font-size: 18px;
        line-height: 1.27;
        @include media-breakpoint-up(md){
            position: absolute;
            top: 45%;
            left: 50%;
            transform: translateX(-50%);
            z-index: 2;
        }
        @include media-breakpoint-up(lg){
            font-size: 23.6px;
        }
        .mdw-imgCaption{
            @include media-breakpoint-down(sm){
                background: $brand-primary;
                box-shadow: inset 0px 12px 18px 0 rgba(26, 24, 25, 0.41);
                text-align: center;
                padding: 40px;
            }
        }
    }
}

.mdw-Brands{
    .subHeading{
        margin-bottom: 80px;
    }
    .mdw-logos{
        margin-bottom: 100px;
        .item{
            text-align: center;
        }
        .slick-track{
            display: flex;
            align-items: center;
        }
        .slick-prev, .slick-next{
            height: 40px;
            width: 40px;
            @include media-breakpoint-up(md){
                height: 90px;
                width: 40px;
            }
        }
        .slick-prev:before{
            background: url('/img/arrow-prev.svg');
            background-repeat: no-repeat;
            background-size: contain;
            content: "";
            height: inherit;
            width: inherit;
            display: block;
        }
        .slick-next:before{
            background: url('/img/arrow-next.svg');
            background-repeat: no-repeat;
            background-size: contain;
            content: "";
            height: inherit;
            width: inherit;
            display: block;
        }
    }
}

.mdw-cat{
    position: relative;
    margin-bottom: 50px;
    picture, img{
        width: 100%;
    }
    &::after{
        background: $brand-primary;
        mix-blend-mode: multiply;
        content: "";
        position: absolute;
        top: 0;
        right: 12px;
        bottom: 0;
        left: 12px;
        z-index: 1;
        opacity: 0;
        transition: opacity 250ms ease;
    }
    .catCaption{
        background: rgba(255,255,255,0.8);
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        color:$brand-primary;
        font-size: 14px;
        font-family: $fontTitles;
        font-weight: bold;
        text-align: center;
        padding: 10px 15px;
        position: absolute;
        left: 50%;
        bottom: 0;
        max-width: 80%;
        transform: translateX(-50%);
        z-index: 2;
        white-space: nowrap;
        @include media-breakpoint-up(md){
            font-size: 17px;
            padding: 20px 10px;
        }
        @include media-breakpoint-up(lg){
            font-size: 12px;
        }
        @include media-breakpoint-up(xl){
            font-size: 14px;
        }
        @media (min-width: 1900px){
            font-size: 17px;
        }
    }
    &:hover::after{
        opacity: 1;
    }
}

.mdw-testimonial{
    .slick-list{
        padding-bottom: 40px;
    }
    .slick-track{
        display: flex;
        .slick-slide{
            height: auto;
            padding-left: 15px;
            padding-right: 15px;
            >div,
            .mdw-testimonial_wrap{
                height: 100%;
            }
        }
    }
    .slick-dots{
        bottom: -20px;
        padding-left: 0;
        text-align: center;
        li{
            button:before{
                font-size: 14px;
            }
            &.slick-active button:before{
                color:#254179;
                opacity: 1;
            }
        }
    }

    &_wrap{
        @include media-breakpoint-down(sm){
            padding: 0 20px;
            margin-bottom: 25px;
        }
    }
    &_item{
        background: $brand-light;
        box-shadow: 0 10px 20px 0 rgba(0,0,0,0.3);
        padding: 40px 20px;
        padding-bottom: 50px;
        position: relative;
        @include media-breakpoint-up(md){
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            padding: 40px;
        }
        &::after{
            background: $brand-primary;
            content: "";
            height: 4px;
            width: 40%;
            position: absolute;
            bottom: 0;
        }
        &.test1::after{
            left: 0;
        }
        &.test2::after{
            right: 0;
        }
        p{
            color:$brand-text;
            line-height: 1.33;
            margin: 0 0 50px 0;
        }
        span{
            color:$brand-secondary;
            font-size: 18px;
            font-weight: bold;
        }
    }
}

