.is_brands{
    picture,picture img{
        width: 100%;
    }
}

.mdw-brands_body{
    h1{
        margin-bottom: 50px;
        @include media-breakpoint-up(lg){
            margin-bottom: 100px;
        }
    }
    .mdw-brands_item{
        margin-bottom: 50px;
        @include media-breakpoint-up(lg){
            margin-bottom: 100px;
        }
        &-meta{
            padding: 35px 8.33333333%;
            @include media-breakpoint-up(md){
                padding: 50px  50px 0;
            }
            h2{
                color:$brand-primary;
                font-size: 22px;
                font-weight: bold;
                @include media-breakpoint-up(lg){
                    font-size: 26px;
                }
            }
            p{
                font-size: 18px;
                margin-bottom: 35px;
                @include media-breakpoint-up(lg){
                    font-size: 20px;
                }
            }
        }
    }
}

.mdw-brands-serv{
    margin-bottom: 50px;
    position: relative;
    &Body{
        background: rgba($brand-primary,0.95);
        color:white;
        font-family: $fontBase;
        padding: 30px 35px;
        position: absolute;
        left: calc(var(--bs-gutter-x) * 0.5);
        right: calc(var(--bs-gutter-x) * 0.5);;
        bottom: 0;
        z-index: 2;
        @include media-breakpoint-up(xl){
            padding: 30px;
        }
        h3{
            font-size: 18px;
            font-weight: bold;
            @include media-breakpoint-up(xl){
                font-size: 24px;
            }
        }
        p{
            font-size: 18px;
            font-weight: 500;
            @include media-breakpoint-up(xl){
                font-size: 18px;
            }
        }
        a{
            border:1px solid $brand-light;
            color: $brand-light;
        }
    }
}